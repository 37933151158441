import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getLogsOfRowId } from "../../services/plot.service";
import { convertFirebaseTimestampToString } from "../../services/helpers";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import Loading from "../Loading.component";
import DataLogChart from "./DataLogChart.component";

import Plot from 'react-plotly.js'; // REMOVE. JUST TESTING IT HERE

const DataLog = (props: any) => {
    const {
        selectedRows,
        dataOrigin,
        mainPlot,
    } = props;

    const [alert, setAlert] = useState({type: "", message: ""});
    const [showModalDataLog, setShowModalDataLog] = useState(false);
    const [logs, setLogs] = useState([] as any);
    const [dirty, setDirty] = useState(false);
    const [filter, setFilter] = useState({ limit: 100, order: "asc"});
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [countFullLog, setCountFullLog] = useState(0);
    const [isRawLog, setIsRawLog] = useState(false);

    const [totalDataPoints, setTotalDataPoints] = useState(null as any);
    const [totalDataPointsPerStage, setTotalDataPointsPerStage] = useState(null as any);

    useEffect(() => {
        if (showModalDataLog) {
            if (selectedRows && selectedRows[0] && selectedRows[0].id)
            getLogsOfSelectedRow();
            return;
        }

        setLogs([]);
    }, [showModalDataLog]);

    /*
    const _countDataPointsPerChannel = (logData: any, selectedRow: any) => {
        const channels: any = [];
        // 1. For each logData.rawdata, get the "channel" values and push to channels array as {channel, dataPoints}. Remove duplicates. Order by ASC
        // 2. Get the length of each selectedRow[CHx].data.fit.length and push to channels array in dataPoints property.
        logData.rawdata.forEach((raw: any) => {
            if (!channels.some((channel: any) => channel.channel === raw.channel)) {
                channels.push({channel: raw.channel, dataPoints: 0});
            }
        });

        Object.keys(selectedRow).forEach((key: string) => {
            // Will include only CHx and not CHx-something
            if (key.includes("CH") && !key.includes("-") && selectedRow[key].data && selectedRow[key].data.fit) {
                const channel: any = channels.find((channel: any) => `CH${channel.channel}` === key);
                if (channel) {
                    channel.dataPoints = selectedRow[key].data.fit.length;
                }
            }
        });

        setTotalDataPoints(channels);
    };
    */

    const _countDataPointsPerStage = (logData: any, selectedRow: any) => {
        // Each logData.rawdata contains a "stage" value and a "channel" value.
        // _countDataPointsPerChannel already counts the data points per channel.
        // This function will count the data points per stage.

        const stages: any = [];
        logData.rawdata.forEach((raw: any) => {
            if (!stages.some((stage: any) => stage.stage === raw.label_stage)) {
                stages.push({stage: raw.label_stage, dataPoints: 0});
            }
        });

        // Até aqui eu só peguei os stages, agora eu preciso contar os data points de cada stage
        // Para isso, eu preciso pegar o rawdata de cada stage e contar os data points de cada channel
        // Depois, eu somo todos os data points de cada channel
        // Hum... não sei se esta lógica está correta. Perguntar ao Qin.
        stages.forEach((stage: any) => {
            const dataPointsPerStage: any = [];
            logData.rawdata.forEach((raw: any) => {
                if (raw.label_stage === stage.stage) {
                    dataPointsPerStage.push(raw);
                }
            });

            const channels: any = [];
            dataPointsPerStage.forEach((raw: any) => {
                if (!channels.some((channel: any) => channel.channel === raw.channel)) {
                    channels.push({channel: raw.channel, dataPoints: 0});
                }
            });

            Object.keys(selectedRow).forEach((key: string) => {
                // Will include only CHx and not CHx-something
                if (key.includes("CH") && !key.includes("-") && selectedRow[key].data && selectedRow[key].data.fit) {
                    const channel: any = channels.find((channel: any) => `CH${channel.channel}` === key);
                    if (channel) {
                        const dataPoints = dataPointsPerStage.filter((raw: any) => raw.channel === channel.channel).length;
                        channel.dataPoints = dataPoints;
                    }
                }
            });

            let totalDataPoints = 0;
            channels.forEach((channel: any) => {
                totalDataPoints += channel.dataPoints;
            });

            stage.dataPoints = totalDataPoints;
        });

        setTotalDataPointsPerStage(stages);
        // console.log("_countDataPointsPerStage", stages);
    };

    const getLogsOfSelectedRow = async () => {
        setLoadingLogs(true);
        const payload: any = {
            rowId: selectedRows[0].id,
            dataOrigin,
            limit: filter.limit,
            order: filter.order
        };

        setLogs([]);
        setIsRawLog(false);

        await getLogsOfRowId(payload).then((res: any) => {
            setDirty(false);
            if (res.message === "OK") {

                if (res.rawLog) {
                    setIsRawLog(true);
                }

                // _countDataPointsPerChannel(res.logfile, selectedRows[0]);
                _countDataPointsPerStage(res.logfile, selectedRows[0]);

                console.log(res.logfile);

                setLogs(res.logfile);
                setCountFullLog(res.countFullLog);
                setLoadingLogs(false);
                return;
            }

            setCountFullLog(0);
            setLoadingLogs(false);
            setAlert({type: "danger", message: res.message});
            console.log('Error getLogsOfRowId', res);
        }).catch((err: any) => {
            setCountFullLog(0);
            setAlert({type: "danger", message: err.message});
            setLoadingLogs(false);
            console.log('Catch getLogsOfRowId', err);
        });
    };

    const _downloadLogs = () => {
        // Convert ARRAY to simple string, one line per entry
        let logsString = "";
        logs.forEach((log: any) => {
            logsString += JSON.stringify(log, null, 2).replace(/\\n/g, "") + "\n";
        });

        // logsString || JSON.stringify(logs, null, 2)
        const element = document.createElement("a");
        const file = new Blob([logsString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${selectedRows[0]?.id || "Logs"}-${logs.length || ""}-entries.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return false;
    };

    const [selectedTimePoints, setSelectedTimePoints] = useState([] as any);
    // const [timePointsDataToDisplay, setTimePointsDataToDisplay] = useState([] as any);
    const [yValues, setYValues] = useState([] as any);

    useEffect(() => {
        if (logs.rawdata && logs.rawdata.length > 0 && selectedTimePoints.length > 0) {
            // for each selectedTimePoints, get the currents and plot the chart
            const yValues: any = [];
            selectedTimePoints.forEach((timePoint: any) => {

                // eBegin is a number. Eg.: -20.
                // eEnd is a number. Eg.: 20.
                // step is a number and corresponds to "skips" from eBegin to eEnd. Eg.: step = 10.
                // currents is an array of numbers. It will be the Y axis of the plot. The X axis will be the step itself.
                // currents.length should be equal to (eEnd - eBegin) / step + 1
                // Each current will be plotted in the X axis according to the step.

                // Eg.: eBegin = -20, eEnd = 20, step = 10
                // Example of currents: []
                // It will plot 5 points in the X axis: -20, -10, 0, 10, 20
                // The Y axis will be the currents array. Eg.: [0.1, 0.2, 0.3, 0.4, 0.5]
                // The first point will be (-20, 0.1), the second point will be (-10, 0.2), and so on.

                const values: any = {
                    x: Array.from({length: timePoint.currents.length}, (_, i) => timePoint["E Begin"] + (i * timePoint["E Step"])),
                    y: timePoint.currents,
                    color: Array.from({length: timePoint.currents.length}, (_, i) => timePoint["color"]),
                    channel: timePoint.channel,
                };

                yValues.push(values);

                // yValues.push({
                //     ...timePoint,
                //     eBegin: timePoint["E Begin"],
                //     eEnd: timePoint["E End"],
                //     step: timePoint["E Step"],
                //     time: timePoint.time,
                //     currents: timePoint.currents,
                // });
            });

            console.log("yValues", yValues);
            setYValues(yValues);
        }
    }, [selectedTimePoints]);



    const _getChannels = (rawData: any) => {
        const channels: any = [];
        if (!rawData) return channels;

        rawData.forEach((raw: any) => {
            if (!channels.some((channel: any) => channel.channel === raw.channel)) {
                channels.push({channel: raw.channel, dataPoints: 0});
            }
        });

        return channels;
    };


    return <>
        <Alert alert={alert} />

        <div className="DataLog">
            <div className="d-grid">
                <button
                type='button'
                disabled={selectedRows && (selectedRows.length === 0 || selectedRows.length > 1)}
                onClick={() => setShowModalDataLog(true)}
                className='btn btn-outline-secondary'>View Logs</button>
            </div>

            <Modal show={showModalDataLog} size="xl">
                <Modal.Header closeButton onClick={() => setShowModalDataLog(false)}>
                    <Modal.Title>Log Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRows && selectedRows[0] && selectedRows[0].id && <>
                        <small className="text-muted">Doc ID: {selectedRows[0].id}</small>

                        {!isRawLog && <>
                            <Separator size={20} />

                            <div className="row align-items-end">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label>List Entries</label>
                                        <select
                                            className="form-control"
                                            defaultValue={filter.limit}
                                            disabled={loadingLogs}
                                            onChange={(e) => {
                                                setFilter({...filter, limit: parseInt(e.target.value)});
                                                setLogs([]);
                                                setDirty(true);
                                            }}>
                                                <option value={100}>100</option>
                                                <option value={300}>300</option>
                                                <option value={1000}>1000</option>
                                                <option value={2000}>2000</option>
                                                <option value={0}>All entries</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>From</label>
                                        <select
                                            className="form-control"
                                            defaultValue={filter.order}
                                            disabled={loadingLogs}
                                            onChange={(e) => {
                                                setFilter({...filter, order: e.target.value});
                                                setLogs([]);
                                                setDirty(true);
                                            }}>
                                                <option value="asc">Begining (equivalent to the first N items)</option>
                                                <option value="desc">End (equivalent to the last N items)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        disabled={loadingLogs}
                                        onClick={() => getLogsOfSelectedRow()}>
                                            {loadingLogs && <Loading loading={loadingLogs} parent="inline" />}
                                            {!loadingLogs && <i className="fas fa-check me-2"></i>} Apply
                                    </button>
                                </div>
                            </div>
                        </>}
                    </>}

                    {!isRawLog && <>
                        <Separator size={10} />

                        <small className="text-muted">

                            {filter.limit === 0 && <>
                                Will show all entries of {countFullLog}
                            </>}

                            {filter.limit !== 0 && <>
                                Will fetch the <strong className="text-primary">{filter.order === "asc" ? "first" : "last"} {filter.limit}</strong> entries of {countFullLog}
                            </>}

                            {filter.order === "desc" && countFullLog > 0 && <>
                                <br />
                                <div>It shows -1 (lines) because arrays start from 0</div>
                            </>}
                        </small>
                    </>}

                    <Separator size={20} />

                    <Loading loading={loadingLogs} />


                    {isRawLog && <>
                        <div className="row">
                            <div className="col">

                                {/* <DataLogChart
                                    countStages={logs.total_stages-1}
                                    currentStage={logs.total_stages-1}
                                /> */}

                                {/* CHART TEST */}
                                <Separator size={10} />

                                <strong>Raw data</strong>

                                <Separator size={30} />


                                <Plot
                                    data={
                                        selectedTimePoints.length > 0 ? yValues.map((item: any, index: number) => {
                                            return {
                                                x: item.x,
                                                y: item.y,
                                                type: "scatter",
                                                mode: "lines",
                                                line: {
                                                    color: item.curveColor,
                                                },
                                                name: `CH${item.channel}`,
                                                channel: `${item.channel}`,
                                                rowIndex: index,
                                            }
                                        }) : []
                                    }
                                    layout={{
                                        // showlegend: true,
                                        autosize: true,
                                        width: 900,
                                        xaxis: {
                                            range: [-700, 200],
                                            dtick: 50,
                                            // autorange: true,
                                        },
                                        yaxis: {
                                            range: [-40, 40],
                                            autorange: true,
                                        },
                                        margin: {
                                            l: 30,
                                            r: 0,
                                            b: 20,
                                            t: 30,
                                            pad: 4
                                        }
                                    }}
                                    config={{displayModeBar: true, displaylogo: false}}
                                />

                                {/*
                                <pre>
                                    {selectedTimePoints && JSON.stringify(selectedTimePoints, null, 2)}
                                    <hr />
                                    {yValues && JSON.stringify(yValues, null, 2)}
                                </pre>
                                */}
                                {/* CHART TEST */}

                                <Separator size={50} />

                                <strong>Curves</strong>

                                <Separator size={30} />

                                {mainPlot}

                                <Separator size={30} />

                                <ul className="list-unstyled">
                                    <li><strong>_channel: </strong> {logs._channel}</li>
                                    <li><strong>_id: </strong> {logs._id}</li>
                                    <li><strong>reader_id: </strong> {logs.reader_id}</li>
                                    <li><strong>assay_manager_version: </strong> {logs.assay_manager_version}</li>
                                    <li><strong>chip_type: </strong> {logs.chip_type}</li>
                                    <li><strong>Channels (assumed by <i>chip_type</i>): </strong> {logs.label_channels}</li>
                                    <li><strong>Channels (calculated by data): </strong> -</li>
                                    <li><strong>filename: </strong> {logs.filename}</li>
                                    <li><strong>sensor_config_version: </strong> {logs.sensor_config_version}</li>
                                    <li><strong>stage: </strong> {logs.stage}</li>
                                    <li><strong>createdAt: </strong> {convertFirebaseTimestampToString(logs.createdAt)}</li>
                                    <li><strong>elapsed_time: </strong> {convertFirebaseTimestampToString(logs.elapsed_time)}</li>
                                    <li><strong>start_time: </strong> {convertFirebaseTimestampToString(logs.start_time)}</li>
                                    <li><strong>total_duration: </strong> {logs.total_duration}</li>
                                    <li><strong>Total Stages (calculated): </strong> {logs.total_stages}</li>
                                    <li><strong>rawdata: </strong> <span className="text-muted">in the right column &rarr;</span></li>
                                </ul>

                                <Separator size={10} />

                                {totalDataPointsPerStage && <>
                                    <strong>Data Points per stage:</strong>

                                    <Separator size={10} />

                                    <ul>
                                        {totalDataPointsPerStage.map((item: any, index: number) => {
                                            return <li key={`stages-data-points-${index}`}>
                                                <strong>Stage {item.stage}:</strong> {item.dataPoints} data points
                                            </li>
                                        })}
                                    </ul>
                                </>}
                            </div>
                            <div className="col">

                                {/* SELECT BY STAGE */}
                                <div className="card bg-light border-0">
                                    <div className="card-body">

                                        <div className="row align-items-center">
                                            <div className="col">
                                                <small className="text-muted">Select by <strong>Stage</strong></small>
                                            </div>
                                            {Array.from({length: logs.total_stages}, (_, i) => i).map((stage: number) => {
                                                return <div className="col text-center" key={`stage-${stage}`}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e: any) => {
                                                                const stageItems: any = logs.rawdata.filter((item: any) => item.label_stage === stage);
                                                                if (e.target.checked) {
                                                                    setSelectedTimePoints([...selectedTimePoints, ...stageItems]);
                                                                } else {
                                                                    setSelectedTimePoints(selectedTimePoints.filter((item: any) => item.label_stage !== stage));
                                                                }
                                                            }} /> Stage {stage}<br />
                                                            <small className="text-muted">({logs.rawdata.filter((item: any) => item.label_stage === stage).length || 0} items)</small>


                                                            {/*
                                                            {_getChannels(logs.rawdata).map((channel: any, index: number) => {
                                                                return <div className="ms-1 ps-3" key={`channel-${channel.channel}`} style={{ borderLeft: "solid 3px #ccc" }}>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={(e: any) => {
                                                                                const channelItems: any = logs.rawdata.filter((item: any) => item.channel === channel.channel && item.stage === stage);
                                                                                if (e.target.checked) {
                                                                                    setSelectedTimePoints([...selectedTimePoints, ...channelItems]);
                                                                                } else {
                                                                                    setSelectedTimePoints(selectedTimePoints.filter((item: any) => item.channel === channel.channel && item.stage !== stage));
                                                                                }
                                                                            }} /> Channel {channel.channel} <small className="text-muted">
                                                                                ({logs.rawdata.filter((item: any) => item.channel === channel.channel && item.stage === stage).length || 0})
                                                                            </small>
                                                                    </label>
                                                                </div>
                                                            })}
                                                            */}

                                                    </label>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <Separator size={10} />

                                {/* SELECT BY CHANNEL */} 
                                <div className="card bg-light border-0">
                                    <div className="card-body">

                                        <div className="row align-items-center">
                                            <div className="col">
                                                <small className="text-muted">Select by <strong>Channel</strong></small>
                                            </div>
                                            {_getChannels(logs.rawdata).map((channel: any, index: number) => {
                                                return <div className="col text-center" key={`channel-${channel.channel}`}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e: any) => {
                                                                const channelItems: any = logs.rawdata.filter((item: any) => item.channel === channel.channel);
                                                                if (e.target.checked) {
                                                                    setSelectedTimePoints([...selectedTimePoints, ...channelItems]);
                                                                } else {
                                                                    setSelectedTimePoints(selectedTimePoints.filter((item: any) => item.channel !== channel.channel));
                                                                }
                                                            }} /> Channel {channel.channel}<br />
                                                            <small className="text-muted">
                                                                ({logs.rawdata.filter((item: any) => item.channel === channel.channel).length || 0}) items
                                                            </small>
                                                    </label>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <Separator size={30} />

                                {logs.rawdata && logs.rawdata.length > 0 && <>
                                    <div className="scrollable">
                                        <div className="accordion" id="accordionExample">

                                            {logs.rawdata.map((raw: any, index: number) => {
                                                return <React.Fragment key={`raw-log-${index}`}>

                                                    <div className="row align-items-center">
                                                        <div className="col-1 text-center">

                                                            <input
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        setSelectedTimePoints([...selectedTimePoints, raw]);
                                                                    } else {
                                                                        setSelectedTimePoints(selectedTimePoints.filter((item: any) => item !== raw));
                                                                    }
                                                                }}
                                                                checked={selectedTimePoints.some((item: any) => item === raw)}
                                                                type="checkbox"
                                                            />

                                                        </div>
                                                        <div className="col">

                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button
                                                                        className="accordion-button collapsed"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapseOne-${index}`}
                                                                        aria-expanded="true"
                                                                        aria-controls={`collapseOne-${index}`}>
                                                                            time: {raw.time} (stage: {raw.label_stage} | channel: {raw.channel}) - diff: {parseFloat(raw.differenceFromPreviousTime).toFixed(3)}
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapseOne-${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">

                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <ul className="list-unstyled">
                                                                                    <li><strong>E Begin: </strong> {raw["E Begin"]}</li>
                                                                                    <li><strong>E End: </strong> {raw["E End"]}</li>
                                                                                    <li><strong>E Precharge: </strong> {raw["E Precharge"]}</li>
                                                                                    <li><strong>E Step: </strong> {raw["E Step"]}</li>
                                                                                    <li><strong>avg_current: </strong> {raw["avg_current"]}</li>
                                                                                    <li><strong>channel: </strong> {raw["channel"]}</li>
                                                                                    <li><strong>data_count: </strong> {raw["data_count"]}</li>
                                                                                    <li><strong>most_recent_flag: </strong> {raw["most_recent_flag"]}</li>
                                                                                    <li><strong>process_mode: </strong> {raw["process_mode"]}</li>
                                                                                    <li><strong>time: </strong> {raw["time"]}</li>
                                                                                    <li><strong>stage: </strong> {raw["stage"]}</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="col">
                                                                                {raw.currents && raw.currents.length > 0 && <>
                                                                                    <div className="accordion" id="accordionExampleCurrents">

                                                                                        <React.Fragment key={`current-raw-${index}-${raw.time}`}>
                                                                                            <div className="accordion-item">
                                                                                                <h2 className="accordion-header">
                                                                                                    <button
                                                                                                        className="accordion-button"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="collapse"
                                                                                                        data-bs-target={`#collapseTwo-currents-${index}-${raw.time}`}
                                                                                                        aria-expanded="true"
                                                                                                        aria-controls={`collapseTwo-currents-${index}-${raw.time}`}>
                                                                                                            {raw.currents.length} Currents
                                                                                                    </button>
                                                                                                </h2>
                                                                                                <div
                                                                                                    id={`collapseTwo-currents-${index}-${raw.time}`}
                                                                                                    className="accordion-collapse collapse show"
                                                                                                    data-bs-parent="#accordionExampleCurrents">
                                                                                                        <div className="accordion-body">
                                                                                                            <ul>
                                                                                                                {raw.currents.map((current: any, currentIndex: number) => {
                                                                                                                    return <li key={`current-raw-${currentIndex}-${raw.time}`}>{current}</li>
                                                                                                                })}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>

                                                                                    </div>
                                                                                </>}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            })}

                                        </div>
                                    </div>
                                </>}

                            </div>
                        </div>
                        
                    </>}


                    {!isRawLog && !dirty && <>
                        {!loadingLogs && logs.length === 0 && <strong>No logs found.</strong>}

                        {!loadingLogs && logs.length > 0 && <>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => _downloadLogs()}>
                                    <i className="fas fa-download me-2"></i> Download Log
                            </button>

                            <Separator size={20} />

                            <ul className="list-group">
                                {logs.map((log: any, index: number) => {
                                    return (
                                        <li key={`log-entry-${index}`} className="list-group-item">
                                            <small className="text-muted me-2">
                                                {filter.order === "asc" ? index : (countFullLog-1) - index}
                                            </small> {JSON.stringify(log, null, 2).replace(/\\n/g, "")}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>}
                    </>}

                    <Separator size={20} />

                    <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalDataLog(false)}>
                        Close
                    </button>
                </Modal.Body>
            </Modal>

        </div>
    </>
};

export default DataLog;