import { useCallback, useEffect, useState, CSSProperties, useContext } from 'react';
import './Charts.scss';
import {
  canAccess,
  getRamdomColor,
  prefixSelectFields,
  selectedFieldsNamesThatContainsYvalues
} from '../../services/helpers';
import axios from 'axios';
import Loading from '../../components/Loading.component';
import FilterBar from '../../components/FilterBar.component';
import Plot from 'react-plotly.js';
import { Chart, registerables } from 'chart.js';

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor'

import NormalizedFilter from '../../components/NormalizedFilter.component';
import LoadMoreBar from '../../components/LoadMoreBar.component';
import Separator from '../../components/Separator.component';
import { Modal } from 'react-bootstrap';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import { batchUpdateDocument } from '../../services/batchUpdate.service';
import Alert from '../../components/Alert/Alert.component';
import { downloadPickleFile, updateColumnValue, getAmountDataByCollection, getDynamicColumns} from '../../services/plot.service';
import LegendChart from '../../components/LegendChart.component';
import { buildXlsxRaw } from '../../services/xlsxRaw.service';
import AppendTemplate from '../../components/AppendTemplate.component';
import ReadersGrid from "../../components/ReadersGrid.component";
import { buildXlsxReaderCalling } from '../../services/xlsxReaderCalling.service';
import { editUser } from '../../services/users.service';
import DataAnalysis from '../../components/DataAnalysis/DataAnalysis.component';
import SetAxisRangeToSaveSession from '../../components/SetAxisRangeToSaveSession.component';
import DataBatchEdit from '../../components/DataBatchEdit/DataBatchEdit.component';
import DataLog from '../../components/DataLog/DataLog.component';
import DataAnalysisCounterQueue from '../../components/DataAnalysisCounterQueue/DataAnalysisCounterQueue.component';
import DataComparisonViewer from '../../components/DataComparison/DataComparison.component';
import DocumentsManagement from '../../components/DocumentsManagement/DocumentsManagement';
import { RemoteConfigContext } from '../../context/remoteConfig.context';

const defaultMode = 'lines'
const defaultWidthLine = 2
const plotWidth = 550;


Chart.register(...registerables);

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL ?? "";

function Charts(props: any) {

  // Do not add any other code here
  useEffect(() => {
    if (!canAccess(props.user, 'plot')) {
      window.location.href = '/home';
      return;
    }
  }, [props.user, props.user.role]);

  const remoteConfig: any = useContext(RemoteConfigContext);

  const [loadingDataRows, setLoadingDataRows] = useState(false);
  const [loadingDataRowsPaginated, setLoadingDataRowsPaginated] = useState(false);
  const [dataRows, setDataRows] = useState([] as any[]);
  const [chartDataOriginalSets, setChartDataOriginalSets] = useState([]as any[])
  const [chartDataMainSets, setChartMainDataSets] = useState([]as any[])
  const [chartDataNormalizedSets, setChartDataNormalizedSets] = useState([]as any[])
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const [axisRangeToSaveSession, setAxisRangeToSaveSession] = useState(false)
  const [maxYAxisValue, setMaxYAxisValue] = useState(0);
  const [maxXAxisValue, setMaxXAxisValue] = useState(30);
  const [minXAxisValue, setMinXAxisValue] = useState(0);
  const [rangeMaxMinAxisGlobal, setRangeMinMaxAxisGlobal] = useState(
    {
      xAxis: {
        min: minXAxisValue, 
        max:maxXAxisValue
      }, 
      yAxis: {
        min: 0, 
        max: 80
      }
    }
  )

  const [rangeMaxMinAxisNormalized, setRangeMinMaxAxisNormalized] = useState(
    {
      xAxis: {
        min: minXAxisValue,
        max: 30
      }, 
      yAxis: {
        min: 0,
        max: 1.6
      }
    }
  )

  // This value comes from the FilterBar component
  const [filterCriteria, setFilterCriteria] = useState(
    {
      dataOrigin: "usb_data",
      ranges: rangeMaxMinAxisGlobal, 
      rangesNormalized: rangeMaxMinAxisNormalized, 
      normalizedValue: 7,
      dateRange: {
        from: "", 
        to: ""
      }
    }
  );

 
  const [curveColors, setCurveColors] = useState([] as any)
  const [curveColorsDefault] = useState({
    "CH0": "green",
    "CH1": "red",
    "CH2": "blue",
    "CH3": "orange",
    "CH4": "pink",
    "CH5": "magent",
  } as any)
  const [amountCollectionData, setAmountCollectionData] = useState(0);
  const [loadindGetAmountDataCollection, setGetAmountDataCollection] = useState(false);
  const [dynamicColumns, setDynamicColumns] = useState([] as any);

  useEffect(() => {
    const getAmountData = async () => {
      setGetAmountDataCollection(true)
      getAmountDataByCollection({dataOrigin: filterCriteria.dataOrigin}).then((res: any) => {
        setGetAmountDataCollection(false)
        setAmountCollectionData(res.data)
      });
    }
    getAmountData()
  }, [filterCriteria.dataOrigin])

  const _fixReaderCallingName = (name: string) => { 
    if (name.toLowerCase().includes("readercalling")) return name.replace("readercalling", "ReaderCalling");
    return name;
  }

  useEffect(() => { // get Dynamic Columns
    const getColumns = async () => {
      getDynamicColumns().then((res: any) => {
        if (res.response?.status === 500) {
          setAlert({ type: 'danger', message: 'Error getting dynamic columns' });
          return;
        }

        if (!res.data) return;

        const dynamicColumns = res.data
          .sort((a: any,b: any) => a.sort - b.sort)
          .filter((item:any) => item.grid !== undefined )
          .filter((item:any) => {
            if (!props.user?.dynamicColumns) props.user["dynamicColumns"] = {};
            if (Object.keys(props.user.dynamicColumns).includes(item.query)) return true;
            props.user.dynamicColumns[item.query] = true;
            props.setUser(props.user);
            return true;
          })
          .map((item: any) => {
            return {name: _fixReaderCallingName(item.query), header: item.grid, minWidth: 100, defaultFlex: 1};
          })
        setDynamicColumns(dynamicColumns);
        localStorage.setItem('user', JSON.stringify(props.user));
      });
    }
    getColumns()
  }, [])

  const formatPlotImageDownload = {
    format: "png", // one of png, svg, jpeg, webp
    filename: `${filterCriteria.dateRange.from}-${filterCriteria.dateRange.to}`,
    height: null,
    width: null,
    scale: 2, // Multiply title/legend/axis/canvas sizes by this factor
  } as any

  function getNewDataset(){
    const newColor = curveColors.length > 0 ? curveColors[curveColors.length - 1] : getRamdomColor()
    return {
      x: [],
      y: [],
      text: [],
      type: defaultMode,
      mode: defaultMode,
      line: {
        color: newColor,
        width: defaultWidthLine,
      },
      name: 'CH1',
      channel: 'CH1',
      hovertemplate: "%{x}-%{y}",
      rowIndex: '0'
    }
  }

  // Table settings
  const gridStyle = { minHeight: 680 };  

  const getCustomChannelStyle = ({ value }: { value: any }) => {
    const cellStyleGreen: CSSProperties = { 
      backgroundColor: "green", 
      color: "white", 
      textAlign: "center"
    };

    const cellStyleRed: CSSProperties = { 
      backgroundColor: "red", 
      color: "white", 
      textAlign: "center"
    };

    if(parseInt(value,10) === 1){
      return <div style={cellStyleRed}>
        POSITIVE
      </div>
    }else if(parseInt(value, 10) === 0){
      return <div style={cellStyleGreen}>
         NEGATIVE
      </div>
    }else if(parseInt(value,10) === -1){
      return <>NA</>  
    }else{
      return value
    }
  }

  const userMarkedAs = [
    { id: '0', label: '0' },
    { id: '1', label: '1' },
    { id: '-1', label: '-1' }
  ]

  const editorPropsObject = {
    idProperty: 'id',
    dataSource: userMarkedAs,
    collapseOnSelect: true,
    clearIcon: null 
  } 

  const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    "channel": {
      name: 'channel',
      operators: [
        {
          name: 'eq', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            
            if (!filterValue) return true
            if (!value) return false

            return value.toString() === filterValue.toString()
          }
        }
      ]
    },
    "aptRegexContains": {
      name: 'regex',
      operators: [
        {
          name: 'OR', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            let re = new RegExp(filterValue.split(" ").map(item => item.replace("[", "")).join("|"), "i")
            return value.toString().match(re) !== null;
          }
        }
        ,
        {
          name: 'AND', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            let re = new RegExp(filterValue.split(" ").map(item => item.replace("[", "")).map(item => `(?=.*${item})`).join(""), "i")
            return value.toString().match(re) !== null;
          }
        },
        {
          name: 'contains', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            return value.toString().toLowerCase().includes(filterValue.toLowerCase());
          }
        },
        {
          name: 'Equals', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            return value.toString().toLowerCase() === filterValue.toLowerCase();
          }
        },
        {
          name: 'Does not equal', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            return !(value.toString().toLowerCase() === filterValue.toLowerCase());
          }
        },
        {
          name: 'Empty', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            return value === undefined;
          }
        },
        {
          name: 'Not empty', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            return value !== undefined;
          }
        },
        {
          name: 'Starts with', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            let re = new RegExp(`^${filterValue}`, "i")
            return value.toString().match(re) !== null;
          }
        },
        {
          name: 'Ends with', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
            if (!filterValue) return true
            if (!value) return false
            let re = new RegExp(`${filterValue}$`, "i")
            return value.toString().match(re) !== null;
          }
        },
      ]
    },
  });

  const getColumnNameCapitalized = (columnName: string) => {
    if (!columnName) return columnName
    const columnNameCaptilized = columnName.charAt(0).toUpperCase() + columnName.slice(1);
    return columnNameCaptilized.split("_").join(" ");
  }

  const getDefaultVisibleColumns = (columnName: string) => {
    return props.user.dynamicColumns[columnName];
  }

  const getDynamicColumnsArray = () => {
    const dynamicColumnsLocal = dynamicColumns.map((column: any, index: number) => {
      if (column.name.toLowerCase().includes('readercalling')) {
        // console.log(">>>>> COLUMN", column, `${column.name.split(".")[0]}-ReaderCalling`);
        return { 
          // name: `${column.name.split(".")[0]}-${column.header.split("-")[1]}`, 
          name: `${column.name.split(".")[0]}-ReaderCalling`, 
          header: column.header.split("-")[0], 
          minWidth: 100, 
          defaultFlex: 1, 
          editable: false , 
          render: getCustomChannelStyle,
          defaultVisible: getDefaultVisibleColumns(`${column.name.split(".")[0]}-${column.header.split("-")[1]}`)
        };
      } else if (column.name.toLowerCase().includes('usermarkedas')) {
        return { 
          name: `${column.name.split(".")[0]}-${column.header.split("-")[1]}`, 
          header: column.header.split("-").join(" - "), 
          minWidth: 100, 
          defaultFlex: 1 , 
          render: getCustomChannelStyle, 
          editor:  SelectEditor, 
          editorProps: editorPropsObject,
          defaultVisible: getDefaultVisibleColumns(`${column.name.split(".")[0]}-${column.header.split("-")[1]}`)
        };
      } else if (column.name.toLowerCase().includes('label')) {
        return { 
          name: `${column.name.split(".")[0]}-${column.header.split("-")[1]}`, 
          header: column.header.split("-").join(" - "), 
          minWidth: 100, 
          defaultFlex: 1 , 
          defaultVisible: getDefaultVisibleColumns(`${column.name.split(".")[0]}-${column.header.split("-")[1]}`)
        };
      } else if (column.name.toLowerCase().includes('createdat')) {
        return { 
          name: 'createdAt', 
          header: 'Start time', 
          minWidth: 210, 
          defaultFlex: 2, 
          render: ({value}:any) => (new Date(value)).toLocaleString("en-US", { timeZone: "America/Los_Angeles"}),
          defaultVisible: getDefaultVisibleColumns('createdAt')
        }
      } else if(column.name.toLowerCase().includes('reader_name')) {
        return { 
          name: `${column.name}`, 
          // name: `${column.name.split(".")[1]}`, 
          header: getColumnNameCapitalized(`${column.name.split(".")[1]}`), 
          minWidth: 250, 
          defaultFlex: 1,
          defaultVisible: getDefaultVisibleColumns(`${column.name}`)
        };  
      }
      return { 
        name: `${column.name}`, 
        header: getColumnNameCapitalized(column.header), 
        minWidth: 250, 
        defaultFlex: 1,
        defaultVisible: getDefaultVisibleColumns(column.name)
      };
    });
    return dynamicColumnsLocal;
  }

  // const [columns, setColumns] = useState([] as any)
  let columns: [] = getDynamicColumnsArray();
  // console.log(">>>> DINAMIC COLUMNS", columns)

  const getDynamicColumnsFilterArray = () => {
    const filters = [
      'id', 
      'status', 
      'reader_info.reader_name', 
      'filename', 
      'tag', 
      'reader_id', 
      'results',
      'CH0.readercalling',
      'CH1.readercalling',
      'CH2.readercalling',
      'CH3.readercalling',
      'CH0.userMarkedAs',
      'CH1.userMarkedAs',
      'CH2.userMarkedAs',
      'CH3.userMarkedAs',
      'CH0.label',
      'CH1.label',
      'CH2.label',
      'CH3.label',
      'CH4.label',
      'CH5.label',
      'CH6.label',
      'CH7.label',
      'CH8.label',
      'dataPoints',
    ];

    const dynamicColumnsLocal = dynamicColumns.filter((column: any) => filters.includes(column.name)).map((column: any, index: number) => {
      if (column.name.toLowerCase().includes('readercalling')) {
        return { name: `${column.name.split(".")[0]}-${column.header.split("-")[1]}`, operator: 'eq', type: 'channel', value: '' };
      } else if (column.name.toLowerCase().includes('usermarkedas')) {
        return { name: `${column.name.split(".")[0]}-${column.header.split("-")[1]}`, operator: 'eq', type: 'channel', value: '' };
      } else if (column.name.toLowerCase().includes('filename')) {
        return { name: `${column.name}`, operator: 'OR', type: 'aptRegexContains', value: '' }
      }
      return { name: `${column.name}`, operator: 'contains', type: 'string', value: '' }
    });
    return dynamicColumnsLocal;
  }

  const onEditComplete = useCallback(({ value, columnId, rowId, rowIndex }: any) => {
    try {
      if (!value) return;

      const data = [...dataRows];

      let oldValue = data[rowIndex][columnId];
      
      data[rowIndex][columnId] = value;
      oldValue = oldValue ? oldValue.toString() : ''; 

      const inlineEditColumns = [
        // 'reader_info.reader_name', 
        'filename', 
        'tag', 
        'CH0-userMarkedAs',
        'CH1-userMarkedAs',
        'CH2-userMarkedAs',
        'CH3-userMarkedAs',
        'CH0-label',
        'CH1-label',
        'CH2-label',
        'CH3-label',
        'CH4-label',
        'CH5-label',
        'CH6-label',
        'CH7-label',
        'CH8-label',
        'CH9-label',
        'desc',
      ];
      
      if (!inlineEditColumns.includes(columnId) ) {
        console.log(columnId)
        setAlert({ type: 'danger', message: `Only "${inlineEditColumns.join(',')}" columns can be updated inline` });
        return;
      }

      if (oldValue.trim() === value.trim()) return;
      
      updateColumnValue({id: rowId, column: columnId, value: value, collection: filterCriteria.dataOrigin})
      .then((res: any) => {
        if (res.message === "OK") {
          data[rowIndex][columnId] = value;
          setAlert({ type: 'success', message: 'Data updated successfully' });
          return;
        }
        if (res.response?.data?.customMessage && ["invalid_column", "column_not_exists"].includes(res.response?.data?.customMessage)) {
          setAlert({ type: 'danger', message: `Error: ${res.response?.data?.customMessage}` });
          return;
        }
        data[rowIndex][columnId] = oldValue;
        setAlert({ type: 'danger', message: 'Error updating data' });
      }).catch((err: any) => {
        console.log("ERRO 2",err)
        data[rowIndex][columnId] = oldValue;
        setAlert({ type: 'danger', message: 'Error updating data' });
      })

      setDataRows(data);
    } catch (err: any) {
      console.log("Error", err);
    }
  }, [dataRows])

  // /Table settings

  const setMaxYAxisValueGlobal = (yAxisValue: number) => {
    return maxYAxisValue > yAxisValue ? maxYAxisValue + 10 : yAxisValue;
  }

  useEffect(() => {
    setRangeMinMaxAxisGlobal({
      xAxis: {min: minXAxisValue, max:maxXAxisValue},
      yAxis: {min: filterCriteria.ranges?.yAxis?.min, max: setMaxYAxisValueGlobal(filterCriteria.ranges?.yAxis?.max)}
    })
    setRangeMinMaxAxisNormalized({
      xAxis: {min: minXAxisValue, max:filterCriteria.rangesNormalized?.xAxis?.max},
      yAxis: {min: filterCriteria.rangesNormalized?.yAxis?.min, max:filterCriteria.rangesNormalized?.yAxis?.max}
    })
    
  }, 
  /* eslint-disable-next-line */
  [
      filterCriteria.ranges.yAxis.max, 
      filterCriteria.ranges.yAxis.min, 
      filterCriteria.ranges.xAxis.max, 
      filterCriteria.ranges.xAxis.min,
      filterCriteria.rangesNormalized.yAxis.max, 
      filterCriteria.rangesNormalized.yAxis.min, 
      filterCriteria.rangesNormalized.xAxis.max, 
      filterCriteria.rangesNormalized.xAxis.min,
      filterCriteria.normalizedValue,
      filterCriteria.dataOrigin,
      filterCriteria.normalizedValue, 
      curveColors
    ]
  )

  function getNormalized(params: any){
    setRangeMinMaxAxisNormalized({
      xAxis: {min: params.rangesNormalized?.xAxis?.min, max:params.rangesNormalized?.xAxis?.max},
      yAxis: {min: params.rangesNormalized?.yAxis?.min, max:params.rangesNormalized?.yAxis?.max},
    })
    drawNormalizedChart({normalizedValue: params.normalizedValue, rowsOriginalChart: chartDataOriginalSets})
  }

  function buildCurveColors(): Promise<any> {
    return new Promise((resolve, reject) => {
      let amountOfCurves = 0
      selectedRows.forEach((item: any) => {
        amountOfCurves = amountOfCurves + Object.keys(item).filter((key:any) => key.includes(prefixSelectFields)).length
      })
      setCurveColors([...Array(amountOfCurves)].map(() => getRamdomColor()))
      resolve(true)
    })
  }

  function setNewDataset(x:[], y:[], name: string, mode: string, color: string, text:string, channel: string = '', rowIndex: string = '0') {
    let newColor;
    if (!color) {
      newColor = getRamdomColor()
      setCurveColors([...Array(1)].map(() => color))
    }else{
      newColor = color
    }  
    return {...getNewDataset(), ...{x, y, name, mode, line: {color: color ? color : newColor,width: defaultWidthLine} , text, channel, rowIndex}}
  }

  function getCurveColorDefault(channel: string){
    const defaultChannels = ["CH0", "CH1", "CH2", "CH3", "CH4", "CH5"];
    const channelColor = defaultChannels.includes(channel) ? curveColorsDefault[channel] : getRamdomColor();
    return channelColor;
  }

  function getMaxValueFromAxisValue(value: number, maxXAxisValue:number){
    return value > maxXAxisValue ? value : maxXAxisValue
  }

  function getMinXAxisValue(xAxisValues: number[]):number{
    if (axisRangeToSaveSession) return Math.min(...xAxisValues);
    return 0;
  }

  function getChannelCurveName(filename: string, name: string, index: number){
    const parsedNameNumberValue = parseInt(name.toLowerCase().replace("ch", ""),10);
    return `${filename} - c${parsedNameNumberValue + 1}`
  }

  function drawMainChart() {
    return new Promise((resolve, reject) => {
      if (curveColors.length === 0)  buildCurveColors();
      const selectedFields:string[] = selectedFieldsNamesThatContainsYvalues(selectedRows).sort();
      const datasetMainFinal = [] as any;
      const datasetOriginalFinal = [] as any;
      let maxYAxisValue = 0;
      setChartDataOriginalSets([])
      setChartMainDataSets([])

      selectedRows.forEach((item: any, rowIndex: any) => {
        selectedFields.forEach((itemCH: any, index: number) => {
          if (item[itemCH]?.data) {
            const minXAxisValueLocal = getMinXAxisValue(item[itemCH]?.data.time);
            let x = [] as any, y = [] as any, text = [] as any, name = "", color = "", mode = "", channel = "";
            name = getChannelCurveName(item.filename, itemCH, index);
            item[itemCH].data.fit.forEach((fit: any, indexFit: number) => {
              y.push(fit)
              x.push(item[itemCH].data.time[indexFit] - minXAxisValueLocal)
              text.push(`${item.filename} - c${index + 1}`)
              maxYAxisValue = getMaxValueFromAxisValue(fit,maxYAxisValue);
            });
            mode = 'line';
            channel = `${itemCH}`;
            color = getCurveColorDefault(channel);
            const dataSet = setNewDataset(x,y,name,mode,color,text, channel, rowIndex);
            
            datasetMainFinal.push(dataSet);
            datasetOriginalFinal.push({...dataSet});
          }
        });
      });
      setMaxXAxisValue(maxXAxisValue);
      setMaxYAxisValue(maxYAxisValue);
      setChartDataOriginalSets(datasetOriginalFinal);
      setChartMainDataSets(datasetMainFinal);
      resolve(datasetOriginalFinal);
    })
  };

  function drawNormalizedChart(params: any) {
    let datasetFinal = [] as any;
    if (params.rowsOriginalChart.length < 1) return
    params.rowsOriginalChart.forEach((item: any, rowIndex: any) => {
      let x = [] as any, y = [] as any, text = [] as any, name = "", color = "", mode = ""
      let channel = ""
      y = item.y.map((value: number) => value / getNormalizedValue(item.y, item.x, parseInt(params.normalizedValue, 10)))
      x = item.x
      text = item.text
      name = item.name
      channel = `${item.channel}`
      mode = item.mode
      color = item.line.color
      datasetFinal.push({...getNewDataset(), ...{x, y, name, mode, line: {color:color, width: defaultWidthLine} , text, channel, rowIndex}})
      setChartDataNormalizedSets(datasetFinal)
    })
  }

  function getNormalizedValue(rowsY: any, rowsX: any, normalizedValue: number) {
    const times: any[] = [];
    rowsY.forEach((fit: number, indexFit: number) => {
      if (
        rowsX[indexFit] >= normalizedValue &&
        rowsX[indexFit] < Math.round(normalizedValue) + 1
      )
        times.push(fit);
    });
    const sum = times.reduce((a, b) => a + b, 0);
    const avg = sum / times.length || 1;
    return avg;
  }

  

  function getXlsxReaderCallingFile() {
    if (selectedRows.length === 0) return;
    const xlsxFileName = `${filterCriteria.dateRange.from} to ${filterCriteria?.dateRange.to}.xlsx` 
    buildXlsxReaderCalling({data: selectedRows, nameFile: xlsxFileName}).then((res: any) => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = xlsxFileName;
      link.click();
    });
  }

  function getXlsxRawFile() {
    if (selectedRows.length === 0) return;
    const xlsxFileName = `${filterCriteria.dateRange.from} to ${filterCriteria?.dateRange.to}.xlsx` 
    buildXlsxRaw({data: selectedRows}).then((res: any) => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = xlsxFileName;
      link.click();
    });
  }

  const [alert, setAlert] = useState<any>({ type: '', message: '' });

  // DATA EDIT
  const [showModalDataEdit, setShowModalDataEdit] = useState(false);

  // READERS SELECTION
  const [showModalReadersSelection, setShowModalReadersSelection] =
    useState(false);
  
  const [selectedReaders, setSelectedReaders] = useState([] as any);

  const getSelectedReaders = () => {
    return selectedReaders;
  }

  useEffect(() => {
    const update = () => {
      buildCurveColors().then(() => {
        drawMainChart().then((rowsOriginalChart: any) => {
          drawNormalizedChart({normalizedValue: filterCriteria.normalizedValue, rowsOriginalChart})
        })
      });
    }
    update();
  }, [
    selectedRows,
    axisRangeToSaveSession,
    maxXAxisValue
  ]);


  // /DATA EDIT

  const [loadingDownloadPickle, setLoadingDownloadPickle] = useState(false);
  const _downloadPickleFile = async () => {
    const selectedRowsIds: any[] = [];
    selectedRows.forEach((item: any) => {
      selectedRowsIds.push(item.id);
    });
    setLoadingDownloadPickle(true);
    await downloadPickleFile(selectedRowsIds).then((res: any) => {
      if (res.message !== "OK") {
        console.log("Error downloading pickle file", res);
        setAlert({ type: 'danger', message: 'Error downloading pickle file' });
        setLoadingDownloadPickle(false);
        return;
      }
      const link = document.createElement('a');
      link.href = res.fileUrl;
      link.setAttribute('download', res.fileName);
      document.body.appendChild(link);
      link.click();
      setLoadingDownloadPickle(false);
      setAlert({ type: 'success', message: 'Pickle file downloaded successfully' });
    }).catch((err: any) => {
      console.log("Error downloading pickle file", err);
      setAlert({ type: 'danger', message: 'Error downloading pickle file' });
      setLoadingDownloadPickle(false);
    });
  }

  const onColumnVisibleChange = useCallback(({ column, visible }: any) => {
      const user = JSON.parse(localStorage.getItem('user') || "{}");
      user.dynamicColumns[column.name] = visible;
      user.id = user.uid
      const errorMessage = `Error saving column preferences`;
      editUser(user).then((response: any) => {
        if (response.message === "OK") {
          localStorage.setItem("user", JSON.stringify(user));
          setAlert({ type: 'success', message: 'Columns saved successfully' });
          return;
        }
        setAlert({ type: 'danger', message: errorMessage });
        console.log("Error updating columns (after success)", response);
      }).catch((err: any) => {
        console.log("Error updating columns", err);
        setAlert({ type: 'danger', message: errorMessage });
      });
    }
  , []);
  
  // const isBatchDataColumnSimpleString = (column: string): boolean => {
  //   const isSimpleString: boolean = (["tag", "filename"].includes(column) || column.includes("label"))
  //   return isSimpleString;
  // }

  return <>
    <Alert alert={alert} />

    <div className="container-fluid">
      <FilterBar
        filterCriteriaFromParent={filterCriteria}
        dataRows ={dataRows}
        onFilter={setDataRows}
        onFilterCriteriaChanges={(e: any) => {setDataRows([]); setSelectedRows([]); setFilterCriteria(e)}}
        onLoadingDataRows={setLoadingDataRows}
        getXlsxRawFile={getXlsxRawFile}
        getXlsxReaderCallingFile={getXlsxReaderCallingFile}
        selectedRows={selectedRows}
        onLoadingDataRowsPaginated={loadingDataRowsPaginated}
        setShowModalReadersSelection={setShowModalReadersSelection}
        getSelectedReaders={getSelectedReaders}
        columns={columns}
        user={props.user}
      />

      <Separator size={10} />

      <Loading loading={loadingDataRows} />

      {(!loadingDataRows && !dataRows) && <>
        <div className="text-center">
          <i className="fas fa-times-circle fa-2x text-muted"></i>
          <div className="mt-2">
            No data found for the selected filters.
          </div>
        </div>
      </>}


      {(!loadingDataRows && dataRows && dataRows.length > 0) && <>
        <div className="row text-center">
          <div className="col d-grid">

            <button
              type='button'
              disabled={loadingDataRows || loadingDataRowsPaginated || selectedRows.length === 0}
              onClick={() => setShowModalDataEdit(true)}
              className='btn btn-outline-secondary'>Data Edit</button>

          </div>
          <div className="col d-grid">

            <DataLog
              selectedRows={selectedRows}
              dataOrigin={filterCriteria.dataOrigin}

              // IMPORTANT NOTE:
              // This mainPlot is just DUPLICATING the plot data
              // If changing something here, change below as well
              // Search this file for "mainPlot" to find the other place
              mainPlot={
                <Plot
                  data={chartDataMainSets}
                  layout={{
                    showlegend: false,
                    autosize: true,
                    xaxis: {
                      range: [rangeMaxMinAxisGlobal.xAxis.min, maxXAxisValue],
                      dtick: 5
                    },
                    yaxis: {range: [rangeMaxMinAxisGlobal.yAxis.min, rangeMaxMinAxisGlobal.yAxis.max]},
                    margin: {
                      l: 30,
                      r: 0,
                      b: 20,
                      t: 30,
                      pad: 4
                    }
                  }}
                  onAfterPlot={() => {
                      const myXAxisLayerAbove = document.getElementsByClassName("xaxislayer-above");
                      const newValueXAxis = myXAxisLayerAbove[0].lastChild?.textContent || "0";
                      setMaxXAxisValue(parseInt(newValueXAxis,10));
                  }}
                  config={
                    {
                      displayModeBar: true,
                      displaylogo: false,
                      toImageButtonOptions: formatPlotImageDownload,
                    }
                  }
                />
              } />

          </div>

          <div className="col d-grid">

            <DataComparisonViewer selectedRows={selectedRows} dataOrigin={filterCriteria.dataOrigin} />

          </div>
          <div className="col d-grid">

            <button
              type='button'
              disabled={loadingDataRows || loadingDataRowsPaginated || selectedRows.length === 0 || loadingDownloadPickle}
              onClick={() => _downloadPickleFile()}
              className='btn btn-outline-secondary'>
                <Loading loading={loadingDownloadPickle} parent="inline" /> Download Pickle
              </button>

          </div>
          <div className="col d-grid">
            <AppendTemplate
              selectedRows={selectedRows}
              targetCollection={filterCriteria.dataOrigin}
              onTemplateAppend={(res: any) => console.log("onTemplateAppend", res)}
            />
          </div>

          {/* Documents Management */}
          {/*
          This is using the remoteConfig to enable the feature for specific users
          {(
            remoteConfig.remoteConfig?.feature_documentsManagement && 
            remoteConfig.remoteConfig?.feature_documentsManagement.enable === true && 
            remoteConfig.remoteConfig?.feature_documentsManagement.users.includes(props.user.email)
          ) && <>
          */}
          {props.user.role && ["superadmin", "super_admin"].includes(props.user.role) && <>
            <div className="col col-2 d-grid">
              <DocumentsManagement
                onRemoveDocuments={() => {
                  console.log({ type: 'warning', message: 'Some documents has changed.<br />Click "Filter" to update the results.' });
                }}
                selectedRows={selectedRows}
                collection={filterCriteria.dataOrigin || "usb_data"} />
            </div>
          </>}

          <div className="col col-2 d-grid">
            <DataAnalysis selectedRows={selectedRows} collection={filterCriteria.dataOrigin || "usb_data"} />
          </div>
        </div>

        <div className="row text-center">
          <div className="col-12 col-md-4 offset-md-8">
            <DataAnalysisCounterQueue pending finish error user={props.user} />
          </div>
        </div>

        <Separator size={20} />

        <div style={{ position: 'relative', zIndex: '9999999' }}>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={dataRows}
            style={gridStyle}
            defaultFilterValue={[...getDynamicColumnsFilterArray()]}
            editable={true}
            enableSelection
            onEditComplete={onEditComplete}
            onSelectionChange={(selection: any) => {
              const _selectedRows: any[] = [];
              for (const [, value] of Object.entries(selection.selected)) {
                _selectedRows.push(value);
              }
              setSelectedRows(_selectedRows);
            }}
            enableKeyboardNavigation={true}
            multiSelect
            filterTypes={filterTypes}
            onColumnVisibleChange={onColumnVisibleChange}
          />
        </div>

        <Separator size={10} />

        <div className="row align-items-center">
          <div className="col-12">

            <small className="text-muted me-3">
              Listing {loadingDataRowsPaginated ? <span className="ms-1"><Loading loading={true} parent="inline" /></span> : dataRows.length} rows of {loadindGetAmountDataCollection ? <span className="ms-1"><Loading loading={true} parent="inline" /></span> : amountCollectionData} rows found. {selectedRows.length} rows selected.
            </small>

            <LoadMoreBar 
              filterCriteriaFromParent={filterCriteria}
              dataRows ={dataRows}
              onFilter={setDataRows}
              onFilterCriteriaChanges={setFilterCriteria}
              onLoadingDataRows={setLoadingDataRowsPaginated} 
              getSelectedReaders={getSelectedReaders}
            />

          </div>
        </div>

        <Separator size={20} />


        <div className="row">
          <div className="col-12 col-md-5">

            {/* CHART 1 */}

            <div className="text-center"><small className="text-muted">PLOT</small></div>
            <Separator size={20} />

            {/*
            IMPORTANT NOTE:
            This plot is being DUPLICATED above
            If changing something here, change above as well (if necessary)
            Search this file for "mainPlot" to find the other place
            */}
            <Plot
              data={chartDataMainSets}
              layout={{
                showlegend: false,
                autosize: true,
                xaxis: {
                  range: [rangeMaxMinAxisGlobal.xAxis.min, maxXAxisValue],
                  dtick: 5
                },
                yaxis: {range: [rangeMaxMinAxisGlobal.yAxis.min, rangeMaxMinAxisGlobal.yAxis.max]},
                width: plotWidth,
                margin: {
                  l: 30,
                  r: 20,
                  b: 20,
                  t: 30,
                  pad: 4
                }
              }}
              onAfterPlot={() => {
                  const myXAxisLayerAbove = document.getElementsByClassName("xaxislayer-above");
                  const newValueXAxis = myXAxisLayerAbove[0].lastChild?.textContent || "0";
                  setMaxXAxisValue(parseInt(newValueXAxis,10));                  
              }}
              config={
                {
                  displayModeBar: true, 
                  displaylogo: false,
                  toImageButtonOptions: formatPlotImageDownload
                }
              }
            />
            <SetAxisRangeToSaveSession 
              setAxisRangeToSaveSession={setAxisRangeToSaveSession}
              axisRangeToSaveSession={axisRangeToSaveSession}
            />
          </div>


          <div className="col-12 col-md-5">

            {/* CHART 2 */}

            <div className="text-center"><small className="text-muted">NORMALIZED</small></div>
            <Separator size={20} />

            <Plot
              data={chartDataNormalizedSets}
              layout={{
                showlegend: false,
                autosize: true,
                width: plotWidth,
                margin: {
                  l: 30,
                  r: 20,
                  b: 20,
                  t: 30,
                  pad: 4
                },
                xaxis: {range: [rangeMaxMinAxisNormalized.xAxis.min, rangeMaxMinAxisNormalized.xAxis.max]},
                yaxis: {range: [rangeMaxMinAxisNormalized.yAxis.min, rangeMaxMinAxisNormalized.yAxis.max]}
              }}
              config={
                {
                  displayModeBar: true, 
                  displaylogo: false,
                  toImageButtonOptions: formatPlotImageDownload
                }
              }
            />

            <div className="me-4 pe-3">
              <NormalizedFilter 
                filterCriteriaFromParent={filterCriteria}
                onFilter={setDataRows}
                onFilterCriteriaChanges={setFilterCriteria}
                onLoadingDataRows={setLoadingDataRows} 
                rangeMinMaxAxis={rangeMaxMinAxisNormalized}
                getNormalized={getNormalized}
              />
            </div>
          </div>


          <div className="col-12 col-md-2">

            {/* LEGEND */}

            <LegendChart 
              curvesMain={chartDataMainSets}
              curvesNormalized={chartDataNormalizedSets}
              setMainCurves={setChartMainDataSets}
              setNormalizedCurves={setChartDataNormalizedSets}
            />

          </div>
        </div>
        {/* /.row */}

      </>}{/* /no-data */}
    </div>{/* /.container-fluid */}



    {/* DATA EDIT modal */}

    <DataBatchEdit 
      dynamicColumns={dynamicColumns}
      selectedRows={selectedRows}
      dataOrigin={filterCriteria.dataOrigin}
      showModalDataEdit={showModalDataEdit}
      setShowModalDataEdit={setShowModalDataEdit}
      dataRows={dataRows}
      setDataRows={setDataRows}
    />

    {/* SELECTION DEVICE modal */}

    <Modal show={showModalReadersSelection} size="lg">
        <Modal.Header
          closeButton
          onClick={() => setShowModalReadersSelection(false)}
        >
          <Modal.Title>Readers Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className={`col-12 col-md-12`}>
              <Separator size={10} />

              <ReadersGrid setSelectedReaders={setSelectedReaders} status={"running"}/>
            </div>
            <Separator size={20} />
            <div className={`col-12 col-md-12`}>
              <button
                type="button"
                className="btn btn-outline-primary ms-2"
                onClick={() => setShowModalReadersSelection(false) }
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
    </Modal>
  </>;
}

export default Charts;
